import React from "react";
import { Typography, Empty, Spin } from "antd";
import "./App.less";

type props = {
  color: any;
}

const FormLoading = ({ color }: props) => {
  const { Title } = Typography;
  return (
    <Empty
      style={{ margin: "100px 20px" }}
      image={<Spin style={{ color }} size="large" />}
      description={
        <>
          <Title level={5}>載入中，請稍侯<br />Processing your request, please wait...</Title>
        </>
      }
    />
  );
};

export default FormLoading;
