import React, { useState, useEffect } from "react";
import { Result, Layout, Row, Col, Image, Typography } from "antd";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import qs from "qs";

import "./App.less";
import { get } from "lodash";
import FormLoading from "./FormLoading";
import { FormattedMessage, useIntl } from 'react-intl'

const { Content } = Layout;
const { Title } = Typography;

const FormResult = () => {
    const location = useLocation();
    const [mainColor, setMainColor] = useState('#4285F4');

    const intl = useIntl()
    const { branchUid }: any = useParams();
    const {
        isLoading: isBranchLoading,
        error: branchLoadError,
        data: branch,
    }: any = useQuery(
        ["branch", branchUid],
        () => {
            const queryObject = {
                "branchUid": branchUid,
            };

            const query = qs.stringify({ _where: queryObject });
            return fetch(
                `${process.env.REACT_APP_STRAPI_DOMAIN}/branches?${query}`
            ).then((res) => res.json());
        }
    );

    useEffect(() => {
        if (branch) {
            setMainColor(get(branch, '0.themeColor', '#4285F4'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branch]);

    let prevSuccessResult: any = <FormLoading color={mainColor} />;

    if (isBranchLoading) return prevSuccessResult;

    if (branchLoadError) {
        console.log(`error = ${JSON.stringify(branchLoadError)}`);
        return prevSuccessResult;
    }

    if (!branch) return prevSuccessResult;
    if (!branch.length) return prevSuccessResult;

    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    console.log(
        `received params branch UID = ${branchUid}, ${JSON.stringify(queryStrings)}`
    );

    prevSuccessResult = (
        <Layout>
            <Content>
                <Row className="top-logo-bar" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={{ offset: 0, span: 24 }} md={{ offset: 6, span: 4 }}>
                        <Image
                            preview={false}
                            src={`${process.env.REACT_APP_STRAPI_DOMAIN}${get(branch, '0.logo.url', '')}`}
                        />
                    </Col>
                    <Col className="comment-title" xs={{ offset: 0, span: 24 }} md={{ span: 6 }}>
                        <span><Title level={3}><FormattedMessage
                            id="Ubypbj"
                            defaultMessage="Comment Card"
                            description="意見書 Comment Card"
                        /></Title><hr />
                            <b><FormattedMessage
                                id="BDzEF8"
                                defaultMessage="Branch"
                                description="分店 Branch"
                            />: {get(branch, '0.name', null)}</b><hr />
                            <b><FormattedMessage
                                id="c0qTFD"
                                defaultMessage="Table No"
                                description="枱號 Table No"
                            />: {get(queryStrings, 'tableNo', 'NA')}</b><hr />
                        </span>
                    </Col>
                </Row>
                <Result
                    status="success"
                    title={<FormattedMessage
                        id="FoD7QQ"
                        defaultMessage="Your comment card is successfully submitted, thank you!"
                        description="你的意見書已成功提交，多謝你的寶貴意見！ Your comment card is successfully submitted, thank you!"
                    />}
                />
            </Content>
        </Layout>
    );

    return prevSuccessResult;
};

export default FormResult;
