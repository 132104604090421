import React from "react";
import Mgmt from "./Mgmt";
import "./App.less";
import Login from "./Login";
import { useIsAuthenticated } from "react-auth-kit";

function CommentMgmt() {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated() ? <Mgmt /> : <Login />;
}

export default CommentMgmt;
