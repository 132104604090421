import React, { useState, Fragment } from "react";
import { Row, Col, Divider, Layout, Typography } from 'antd';
import { useLocation } from "react-router-dom";
import qs from "qs";
import { get, lowerCase } from 'lodash';

import "./App.less";
const QRCode = require("qrcode.react");


const QrCodes = () => {
    const [tableNos, setTableNos] = useState('')
    const [brand, setBrand] = useState('')
    const [shopName, setShopName] = useState('')

    const { Content } = Layout;
    const { Title } = Typography;

    const location = useLocation();
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true });
    const branch: any = get(queryStrings, 'branch', '');

    const query = qs.stringify({ _where: { 'branchUid_eq': branch } });
    fetch(
        `${process.env.REACT_APP_STRAPI_DOMAIN}/branches?${query}`
    ).then((res) => res.json())
        .then(data => {
            if (data) {
                setTableNos(get(data, '0.tableNumbers', ''));
                setBrand(get(data, '0.brand', ''));
                setShopName(get(data, '0.name', ''));
            }
        });

    return (
        <Fragment>
            <Layout className="comment-mgmt">
                <Content>
                    <Divider orientation="left">{shopName}</Divider>
                    {tableNos && <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {tableNos.split(',').map((tableNo: string) => {
                            return { url: `${process.env.REACT_APP_QR_DOMAIN}/comment/branch/${branch}?tableNo=${tableNo}`, tableNo }
                        }).map((urlObject: any) => {
                            return (<Col className="gutter-row" style={{ 'textAlign': 'center', 'padding': '10px' }} span={6}>
                                <Title level={4}>枱號 - {urlObject.tableNo}</Title><div style={{ 'padding': '20px' }}><QRCode size={256} value={urlObject.url} /></div>
                            </Col>)
                        })}
                    </Row>}
                </Content>
            </Layout>
        </Fragment >
    );

};

export default QrCodes;
