import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import FormMidori from "./FormMidori";
import CommentMgmt from "./CommentMgmt";
import Login from "./Login";

import { QueryClient, QueryClientProvider } from "react-query";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "react-auth-kit";
// import { PrivateRoute } from "react-auth-kit";
import FormResult from "./FormResult";
import QrCodes from "./QrCodes";
import { IntlProvider } from 'react-intl'
import en from './compiled-lang/en.json';
import zh from './compiled-lang/zh.json';

const queryClient = new QueryClient();

const App = () => {
    const langFiles = {
        zh,
        en
    } as any
    const [lang, setLang] = useState('zh');

    return (
        <IntlProvider messages={langFiles[lang]} locale={lang} defaultLocale="en">
            <AuthProvider authType={"localstorage"} authName={"_auth"}>
                <QueryClientProvider client={queryClient}>
                    <Router>
                        <div>
                            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                            <Switch>
                                <Route path="/comment/login">
                                    <Login />
                                </Route>
                                <Route path="/comment/mgmt">
                                    <CommentMgmt />
                                </Route>
                                <Route path="/comment/branch/:branchUid">
                                    <FormMidori setLang={setLang as any} lang={lang as any} />
                                </Route>
                                <Route path="/comment/result/:branchUid">
                                    <FormResult />
                                </Route>
                                <Route path="/comment/qr-codes">
                                    <QrCodes />
                                </Route>
                            </Switch>
                        </div>
                    </Router>
                </QueryClientProvider>
            </AuthProvider></IntlProvider>
    )
}

export default App;