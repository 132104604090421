export const credentialData = [
  {
    username: "test001",
    password: "0040f2abc2cff0c8f59883b99ae9fab6",
    key: "aE3iCbXxmM",
    authUserState: {
      name: "test001",
      email: "test001@wellcore.hk",
    },
  },
  {
    username: "test002",
    password: "3fc9a732d99ed073918370c4c1de0c65",
    key: "X41m4p73Ku",
    authUserState: {
      name: "test002",
      email: "test002@wellcore.hk",
    },
  },
  {
    username: "dtb001",
    password: "603935eecdf00f4bcebf0e61167878a0", 
    key: "35yB6oT0Hq",
    authUserState: {
      name: "dtb001",
      email: "dtb001@wellcore.hk",
      branchUids: ['DTB-TW', 'DTB-NT', 'DTB-HP', 'DTB-K11', 'DTB-LP', 'DTB-WG']
    },
  },
  {
    username: "dtb002",
    password: "f89bf6c9f73725421032a4ab5942195c", 
    key: "0lzwzFm3Gz",
    authUserState: {
      name: "dtb002",
      email: "dtb002@wellcore.hk",
      branchUids: ['DTB-K11', 'DTB-LP', 'DTB-WG']
    },
  },
  {
    username: "ncg001",
    password: "0236ec72fb944acb5029239020c65cae", 
    key: "0YgiIm5C0g",
    authUserState: {
      name: "ncg001",
      email: "ncg001@wellcore.hk",
      branchUids: ['NCG-SP', 'NCG-LY', 'NCG-CO', 'NCG-DH', 'NCG-EP', 'NCG-LP', 'NCG-MC', 'NCG-PM', 'NCG-MP', 'NCG-DH-R']
    },
  },
  {
    username: "ncg002",
    password: "ad16b98db50f3e20e09005f62e3be876", 
    key: "fMjTITUsGQ",
    authUserState: {
      name: "ncg002",
      email: "ncg002@wellcore.hk",
      branchUids: ['NCG-DH', 'NCG-EP', 'NCG-LP', 'NCG-MC', 'NCG-PM', 'NCG-MP', 'NCG-DH-R']
    },
  },
  {
    username: "ghb1",
    password: "241e4915a863bc526c699423c000be5c", 
    key: "xksujvjn0x",
    authUserState: {
      name: "ghb1",
      email: "ghb1@wellcore.hk",
      branchUids: ['GHB-DH', 'GHB-CO', 'GHB-WTS', 'GHB-TO', 'GHB-DH-R']
    },
  },
  {
    username: "ghs1",
    password: "3b7974be9064773fd58e0a1b9255cd44", 
    key: "c9bjznkcok",
    authUserState: {
      name: "ghs1",
      email: "ghs1@wellcore.hk",
      branchUids: ['GHS-K11', 'GHS-WTS', 'GHS-OP']
    },
  },
  {
    username: "ky1",
    password: "ed01e47c32cf7a2a560a25ddc20b5751", 
    key: "7aan1v4r1t",
    authUserState: {
      name: "ky1",
      email: "ky1@wellcore.hk",
      branchUids: ['KY-K11', 'KY-MT']
    },
  },
  {
    username: "md1",
    password: "df730a6a5c2a47afb81753fbfec0d8bc", 
    key: "iqw3aboua8",
    authUserState: {
      name: "md1",
      email: "md1@wellcore.hk",
      branchUids: ['MD-GP', 'MD-MS', 'MD-NT', 'MD-MP']
    },
  },
  {
    username: "un1",
    password: "d663f9efbcc39939910d581bf7bca785", 
    key: "jvddrs6ay6",
    authUserState: {
      name: "un1",
      email: "un1@wellcore.hk",
      branchUids: ['UN-IS', 'UN-HP', 'UN-WG', 'UN-EP']
    },
  },
  {
    username: "tr1",
    password: "6447efc55b8d49d466bd5f116a14d1ea", 
    key: "qj6zrhrq4g",
    authUserState: {
      name: "tr1",
      email: "tr1@wellcore.hk",
      branchUids: ['TR-SP']
    },
  },

];
