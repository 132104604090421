import React, { useState } from "react";
import { message, Button, Layout, Row, Col, Typography, Image, Form, Input, Space, Spin } from "antd";
import "./App.less";
import { useSignIn } from "react-auth-kit";
import { credentialData } from "./credentials";
import { filter } from "lodash";
import { useHistory } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";
import CommentMgmt from "./CommentMgmt";
import logo from './wellcore.jpg'

const { Title } = Typography;
const md5 = require("md5");
const jwt = require("jsonwebtoken");

const TOKEN_EXPIRE_IN_MINS = 10;
const DEFAULT_SPIN_LOADING_MS = 3000;

const LoginComponent = () => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const signIn = useSignIn();
  const [form] = Form.useForm();
  const [formRef, setFormRef] = useState(null as any);
  const [isLoading, setIsLoading] = useState(false);
  const { Content } = Layout;

  if (isAuthenticated()) {
    return <CommentMgmt />
  }

  const onFinish = async (values: any) => {
    setIsLoading(true);
    if (formRef) {
      setTimeout(() => {
        checkAuthUsingLocalFile(values).then((res: any) => {
          if (res.status === "OK") {
            if (
              signIn({
                token: res.token,
                expiresIn: TOKEN_EXPIRE_IN_MINS,
                tokenType: "Bearer",
                authState: res.authState,
              })
            ) {

              setIsLoading(false);
              return history.push("/comment/mgmt");

            } else {
              setTimeout(() => {
                setIsLoading(false);
                message.error('無法登入，請稍侯再試。 Unable to login, please try again later.');
              }, DEFAULT_SPIN_LOADING_MS);
            }
          } else {
            setTimeout(() => {
              setIsLoading(false);
              message.error('登入電郵或密碼錯誤！ Incorrect email or password entered');
            }, DEFAULT_SPIN_LOADING_MS);
          }
          return;
        });
      }, DEFAULT_SPIN_LOADING_MS);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    setIsLoading(false);
  };

  const checkAuthUsingLocalFile = async (formData: any) => {
    let matchedAccount = filter(credentialData, (credential) => {
      return credential.authUserState.email === formData.email;
    });

    if (matchedAccount.length) {
      const account = matchedAccount[0];
      if (account.password === md5(formData.password)) {
        const token = jwt.sign(account.authUserState, account.key);
        return {
          status: "OK",
          token,
          authState: account.authUserState,
        };
      }
    }

    return {
      status: "FAILED",
    };
  };

  return (
    <Layout className="layout">
      <Content>
        <Spin spinning={isLoading} tip={<>載入中，請稍侯<br />Processing your request, please wait...</> as any}>
          <Row className="top-logo-bar" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={{ offset: 0, span: 24 }} md={{ offset: 7, span: 5 }}>
              <Image
                preview={false}
                src={logo}
              />
            </Col>
            <Col className="comment-title" xs={{ offset: 0, span: 24 }} md={{ span: 4 }}>
              <span><Title level={4}>線上意見系統<br />Online Commentting System</Title></span>
            </Col>
          </Row>
          <Row className="main-desc" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={{ offset: 0, span: 24 }} md={{ offset: 6, span: 10 }}>
              <Form
                name="choco-comment-login"
                colon={false}
                form={form}
                ref={setFormRef}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Space direction="vertical" size="middle">
                  <Form.Item name="email" label="登入電郵 Email">
                    <Input />
                  </Form.Item>
                  <Form.Item name="password" label="登入密碼 Password">
                    <Input.Password />
                  </Form.Item>
                  <Form.Item wrapperCol={{ xs: { offset: 0, span: 24 }, md: { offset: 10, span: 8 } }}>
                    <Button block size="large" htmlType="submit">
                      提交 Submit
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Content>
    </Layout>
  );
};

export default LoginComponent;
