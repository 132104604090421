import React from "react";
import { Typography, Empty } from "antd";
import "./App.less";

const Processing = () => {
  const { Title } = Typography;
  return (
    <Empty
      style={{ margin: "100px 20px" }}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <>
          <Title level={5}>Processing...</Title>
        </>
      }
    />
  );
};

export default Processing;
