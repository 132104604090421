import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  Button,
  Layout,
  Row,
  Col,
  Spin,
  Typography,
  Divider,
  Space,
  Table,
  Popconfirm,
  Image,
  Modal,
  message,
  DatePicker
} from "antd";
import { useQuery } from "react-query";
import moment from "moment";
import { get, flattenDeep, toString } from "lodash";
import { ProfileTwoTone } from "@ant-design/icons";
import "./App.less";
import Processing from "./Processing";
import { useSignOut, useAuthUser } from "react-auth-kit";
import logo from './wellcore.jpg'
import FormMidori from "./FormMidori";
import axios from "axios"
import { CSVLink } from "react-csv";
import { utcToZonedTime } from 'date-fns-tz'
import { format } from 'date-fns';
import {
  Link,
} from "react-router-dom";
import qs from 'qs';

const Mgmt = () => {
  const ADMIN_ACCOUNT_NAME = 'test001';
  
  const signOut = useSignOut();
  const auth = useAuthUser();
  const { RangePicker } = DatePicker;
  let lastSuccessView = <Processing />;
  const { Title } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({});
  const [selectedBranches, setSelectedBranches] = useState([])
  const [matchedBranches, setMatchedBranches] = useState([])

  const [startDate, setStartDate] = useState(moment(moment().subtract(1, 'months'), 'YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment(new Date(), 'YYYY-MM-DD'))

  const { Content, Footer } = Layout;

  useEffect(() => {
    const forms: any = flattenDeep(matchedBranches.map((x: any) => x.formMidoris));
    setSelectedBranches(forms.filter((form: any) => {
      return moment(moment(form.shopSalesOfDate).format('YYYY-MM-DD')).isBetween(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    }));
  }, [matchedBranches, startDate, endDate]);

  const onBranchChange = async (value: any) => {
    if (branches) {
      let _matchedBranches = branches.filter((branch: any) => {
        return value.includes(branch.id)
      });
      setMatchedBranches(_matchedBranches);
    }
  }

  const mapBranch = (brancNo: any) => {
    const target = branches.filter((branch: any) => {
      return toString(branch.id) === toString(brancNo)
    })
    return get(target, '0.name', 'NA');
  }

  const mapBranchUid = (brancNo: any) => {
    const target = branches.filter((branch: any) => {
      return toString(branch.id) === toString(brancNo)
    })
    return get(target, '0.branchUid', 'NA');
  }

  const dataMassage = (records: any) => {
    return records.map((record: any) => {
      let newRecord: any = {};

      newRecord['編號'] = record.id;
      newRecord['惠顧日期'] = format(utcToZonedTime(record.shopSalesOfDate, 'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm:ss');
      // newRecord['惠顧人數'] = record.shopNumberOfCustomers;
      newRecord['枱號'] = record.shopSeatTableNumber;
      newRecord['分店'] = mapBranch(record.branch);

      newRecord['名稱'] = record.customerName;
      newRecord['性別'] = record.customerGender;
      newRecord['年齡'] = record.customerAge;
      newRecord['電話'] = record.customerPhoneNumber;
      newRecord['電郵'] = record.customerEmail;
      newRecord['惠顧次數'] = record.customerNumberOfVisit;

      newRecord['從何得知'] = record.ackChannel;
      newRecord['從何得知其他原因'] = record.ackChannelOthersDetail;
      newRecord['店舖意見'] = record.additionalCommentDetail;

      newRecord['訂閱'] = record.willSubscribeContactList === 'SUBSCRIBE' ? 'YES' : 'NO';

      newRecord['會否再次光臨'] = record.willRevisit ? 'YES' : 'NO';
      newRecord['不再再次光臨原因'] = record.willNotRevisitReasonDetail;

      newRecord['食物:味道評分'] = record.rateFoodTaste;
      newRecord['食物:外觀評分'] = record.rateFoodAppearance;
      newRecord['食物:溫度評分'] = record.rateFoodTemp;
      newRecord['食物:份量評分'] = record.rateFoodVolume;
      newRecord['食物:提供速度評分'] = record.rateFoodEfficiency;

      newRecord['服務:禮貌評分'] = record.rateServicePolite;
      newRecord['服務:效率評分'] = record.rateServiceEfficiency;
      newRecord['服務:細心評分'] = record.rateServiceAttentive;

      newRecord['店舖環境:室溫評分'] = record.rateShopTemp;
      newRecord['店舖環境:清潔評分'] = record.rateShopClean;
      newRecord['店舖環境:氣氛評分'] = record.rateShopAmbience;

      newRecord['希望增加菜式'] = record.wishDishDetail;
      newRecord['建立日期'] = format(utcToZonedTime(record.created_at, 'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm:ss');


      return newRecord;
    })
  }

  const query = qs.stringify({
    branchUid_in: get(auth(), 'branchUids', null),
  });

  const branchCondition = get(auth(), 'branchUids', null) ? query : ''
  const {
    isLoading: isBranchesLoading,
    error: branchesError,
    data: branches,
  }: any = useQuery(
    "branches",
    () =>
      fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/branches?${branchCondition}`).then((res) =>
        res.json()
      ),
    { refetchOnWindowFocus: false }
  );

  const rangeOnChange = async (dates: any, datestrings: any) => {
    setStartDate(moment(datestrings[0], 'YYYY-MM-DD'));
    setEndDate(moment(datestrings[1], 'YYYY-MM-DD'));
  }

  if (isBranchesLoading) {
    return lastSuccessView;
  }

  if (branchesError) {
    console.log(
      `error = ${branchesError.message
      }`
    );
    return lastSuccessView;
  }


  const deleteComment = async (id: any) => {
    setIsLoading(true);
    try {
      await axios.delete(`${process.env.REACT_APP_STRAPI_DOMAIN}/form-midoris/${id}`);
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      console.log("Axios Failed:", err);
      message.error('無法處理你的要求，請稍侯再試。 Unable to process your request, please try again later.');
      setIsLoading(false);
      return;
    }
  }

  const preview = (record: any) => {
    setCurrentModalData(record);
    setIsModalVisible(true);
  }

  const columns = [
    {
      title: '姓名 Name',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: '性別 Gender',
      dataIndex: 'customerGender',
      key: 'customerGender',
    },
    {
      title: '電郵 Email',
      dataIndex: 'customerEmail',
      key: 'customerEmail',
    },
    {
      title: '分店 Branch',
      dataIndex: 'branchName',
      key: 'branchName',
    },
    // {
    //   title: '評分 Rate Overall',
    //   dataIndex: 'rateOverall',
    //   key: 'rateOverall',
    //   render: (text: any, record: any) => {
    //     return text === "GOOD" ? <Tag color="green">滿意 Good</Tag> : text === "FAIR" ? <Tag color="default">一般 FAIR</Tag> : <Tag color="red">不滿意 Unsatisfactory</Tag>;
    //   }
    // },
    {
      title: '收集於 Received At',
      key: 'created_at',
      render: (text: string, record: any) => (
        <Space size="middle">
          {moment(record.created_at).format("DD-MM-YYYY HH:mm")}
        </Space>
      ),
    },
    {
      title: '動作 Action',
      key: 'action',
      render: (text: string, record: any) => (
        <Space size="middle">
          <Modal width={"100%"} okText="關閉 Close" title={`客人意見卡 Customer Comment Card (${get(currentModalData, 'customerName', 'N/A')})`} visible={isModalVisible} onOk={() => setIsModalVisible(false)} onCancel={() => setIsModalVisible(false)} cancelButtonProps={{ hidden: true }}>
            <FormMidori isReadOnly={true} currentFormValues={currentModalData} />
          </Modal>
          <Button onClick={() => preview(record)} type="primary" size={"large"}>
            查看 Preview
          </Button>
          {get(auth(), 'name', '') === ADMIN_ACCOUNT_NAME && <Popconfirm
            placement="top"
            title={`是否移除此意見卡 Are you sure to delete this comment card: (姓名 Name: ${record.customerName}) ?`}
            onConfirm={() => deleteComment(record.id)}
            okText="是 Yes"
            cancelText="否 No"
          >
            <Button danger={true} size={"large"}>
              移除 Remove
            </Button>
          </Popconfirm>}
        </Space>
      ),
    },
  ];

  const SignOutComponent = () => {
    return <Button size="large" type="primary" onClick={() => signOut()}>
      登出系統 Logout
    </Button >
  };

  lastSuccessView = (
    <Layout className="comment-mgmt">
      <Content>
        <Spin spinning={isLoading} tip="Processing Your Request. One moment...">
          <Row className="top-logo-bar" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={{ offset: 0, span: 24 }} md={{ offset: 7, span: 5 }}>
              <Image
                preview={false}
                src={logo}
              />
            </Col>
            <Col className="comment-title" xs={{ offset: 0, span: 24 }} md={{ span: 4 }}>
              <span><Title level={4}>線上意見系統<br />Online Commentting System</Title> {SignOutComponent()} </span>
            </Col>
          </Row>
          <Divider />
          <Row className="comment-system-desc" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={{ offset: 0, span: 24 }} md={{ offset: 4, span: 16 }}>
              <Title level={5}>用戶 User： {get(auth(), 'name', '')}</Title>
              <>
                <Row justify="start" align="middle">
                  <Col span={24}>
                    選擇分店 Pick a Branch
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "75%", padding: "10px 50px" }}
                      onChange={onBranchChange}
                      defaultValue={selectedBranches.map((x: any) => x.id)}
                    >
                      {branches.map((branch: any, index: number) => {
                        return (
                          <Select.Option key={index} value={branch.id}>
                            {branch.brand} - {branch.name} ({branch.branchUid})
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
                <Row justify="start" align="middle">
                  <Col span={24}>
                    選擇時間範圍 Pick a Time Range
                    <RangePicker
                      style={{ marginLeft: '40px', marginTop: '10px', height: '35px' }}
                      defaultValue={[startDate, endDate]}
                      onChange={rangeOnChange}
                    />
                  </Col>
                </Row>

                <Divider />

                {selectedBranches.length ?
                  <Row style={{
                    border: "3px solid #4285F4",
                    padding: "30px",
                    margin: "10px",
                  }}
                    justify="center"
                    align="middle" gutter={16}>
                    <Col className="gutter-row" span={24}>
                      <Title level={3}>
                        <ProfileTwoTone
                          style={{ fontSize: "25px", marginRight: "10px" }}
                        />{" "}
                        意見書 Comment Cards
                      </Title>
                      {selectedBranches.length && <p><CSVLink filename={`Comment Report - ${selectedBranches.map((x: any) => x.branchUid).join('/')}`} data={dataMassage(selectedBranches)}><Button style={{ marginRight: '20px' }} type="primary" size={"large"}>
                        下載意見書報表 (所選擇分店) Download Comment Report (Selected Branches)
                      </Button></CSVLink></p>}
                      {matchedBranches.map((matchedBranch, index) => {

                        const bs = get(matchedBranch, 'formMidoris', []);
                        if (bs.filter((form: any) => {
                          return moment(moment(form.shopSalesOfDate).format('YYYY-MM-DD')).isBetween(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
                        }).length === 0) {
                          return <></>
                        }
                        return <p key={index}><CSVLink filename={`Comment Report - ${get(matchedBranch, 'brand', '')} - ${get(matchedBranch, 'name', '')} (${get(matchedBranch, 'branchUid', '')})`} data={dataMassage(get(matchedBranch, 'formMidoris', []))}><Button style={{ marginRight: '20px' }} type="primary" size={"large"}>
                          {get(matchedBranch, 'branchUid', '')} - 下載分店意見書報表 Download Report
                        </Button></CSVLink><Link target="_blank" rel="noopener noreferrer" to={`/comment/qr-codes?branch=${get(matchedBranch, 'branchUid', '')}`}><Button type="primary" size={"large"} >二維碼頁 QR</Button></Link></p>
                      })}

                      <br /><br />
                      <Table rowKey="id" columns={columns} dataSource={selectedBranches.map((selectedBranch: any) => {
                        return {
                          ...selectedBranch,
                          branchName: mapBranchUid(selectedBranch.branch)
                        }
                      })} />
                    </Col>
                  </Row> : <Title level={3}>沒有找到任何資料，請選擇分店並修改時間範圍 <br />No records found - Select a branch and adjust time range to continue</Title>}
              </>
            </Col></Row>
        </Spin>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Created by Lemon Dice ©2021
      </Footer>
    </Layout>
  );

  return lastSuccessView;
};

export default Mgmt;
