import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { notification, Layout, Row, Col, Space, Form, Input, Button, Radio, Typography, Checkbox, Image, Spin, Divider } from "antd";
import { useQuery } from "react-query";
import qs from "qs";
import axios from "axios";

import { get } from "lodash";
import "./App.less";
import FormLoading from "./FormLoading";
import { FormattedMessage, useIntl } from 'react-intl'
import Processing from "./Processing";

const { Content } = Layout;
const { Title } = Typography;
const DEFAULT_SPIN_LOADING_MS = 1000;

const FormMidori = ({ isReadOnly = false, currentFormValues = {}, setLang = Function, lang = '' }) => {

    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    const { branchUid }: any = useParams();
    const [willRevisit, setWillRevisit] = useState(null);
    const [ackChannel, setAckChannel] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formRef, setFormRef] = useState(null as any);

    const [mainColor, setMainColor] = useState('#4285F4');


    const [form] = Form.useForm();
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl()

    const showError = (data: any) => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        notification['warning']({
            message: <Title level={5}>無法提交 Unable to proceed</Title>,
            description: data
        });
    };

    const {
        isLoading: isBranchLoading,
        error: branchLoadError,
        data: branch,
    }: any = useQuery(
        ["branch", get(currentFormValues, 'branch', branchUid)],
        () => {
            let queryObject: any = {
                "branchUid": branchUid,
            };

            if (get(currentFormValues, 'branch', null)) {
                queryObject = {
                    "id": get(currentFormValues, 'branch', null),
                }
            }

            const query = qs.stringify({ _where: queryObject });
            return fetch(
                `${process.env.REACT_APP_STRAPI_DOMAIN}/branches?${query}`
            ).then((res) => res.json());

        }
    );

    useEffect(() => {
        console.log('currentFormValues', currentFormValues);

        if (isReadOnly && formRef) {
            const _willSubscribeContactList = get(currentFormValues, 'willSubscribeContactList', null);
            const _ackChannel = get(currentFormValues, 'ackChannel', null);
            const _willRevisit = get(currentFormValues, 'willRevisit', null);

            Object.assign(currentFormValues, { willSubscribeContactList: _willSubscribeContactList });
            setAckChannel(_ackChannel);
            setWillRevisit(_willRevisit);
            form.setFieldsValue(currentFormValues);
        }

        if (branch) {
            setMainColor(get(branch, '0.themeColor', '#4285F4'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef, currentFormValues, branch]);

    let prevSuccessResult: any = <FormLoading color={mainColor}/>;

    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    console.log(
        `received params branch UID = ${branchUid}, ${JSON.stringify(queryStrings)}`
    );


    if (isBranchLoading) return prevSuccessResult;

    if (branchLoadError) {
        console.log(`error = ${JSON.stringify(branchLoadError)}`);
        return prevSuccessResult;
    }

    if (!branch) return prevSuccessResult;
    if (!branch.length) return prevSuccessResult;

    console.log(`fetched branch = ${JSON.stringify(branch)}`);

    const onFinish = async (values: any) => {
        setIsLoading(true);

        try {
            await axios.post(`${process.env.REACT_APP_STRAPI_DOMAIN}/form-midoris`, {
                ...values,
                willSubscribeContactList: get(values, 'willSubscribeContactList.0', 'SUBSCRIBE'),
                shopSalesOfDate: new Date(),
                shopSeatTableNumber: get(queryStrings, 'tableNo', 'NA'),
                branch: get(branch, '0.id', null)
            });
        } catch (err) {
            console.log("Axios Failed:", err);
            alert(intl.formatMessage({
                id: "Zr7H5r",
                defaultMessage: "Something went wrong. Please make sure to fill in all information",
                description: "Something went wrong. Please make sure to fill in all information",
            }));
            setIsLoading(false);
            showError("暫時無法提交，請稍後再試。 Unable to proceed, please try again later.");
            return;
        }

        // TODO: validation & prompt
        setTimeout(() => {
            setIsLoading(false);
            form.resetFields();
            history.push({
                pathname: `/comment/result/${branchUid}`,
                search: `?${qs.stringify(queryStrings)}`
            });
        }, DEFAULT_SPIN_LOADING_MS);

        return;
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
        setIsLoading(false);
        showError("你提交的資料有誤，請重新檢查後再試。 Input data is not correct, please check and re-submit the form");
    };

    if (!isReadOnly && !get(queryStrings, 'tableNo', null)) {
        showError("Missing required parameter");
        prevSuccessResult = <Processing />
        return prevSuccessResult;
    }

    prevSuccessResult = (
        <Layout>
            <Content>
                <Spin spinning={isLoading} tip={intl.formatMessage({
                    id: '+YyXWw',
                    defaultMessage: 'Processing your request, please wait...',
                    description: '提交中，請稍侯 Processing your request, please wait...',
                })}>
                    <Row justify="start" align="middle" className="top-logo-bar" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={{ offset: 0, span: 24 }} md={{ offset: 6, span: 2 }}>
                            <Image
                                preview={false}
                                style={{width: '180px', margin: '30px'}}    
                                src={`${process.env.REACT_APP_STRAPI_DOMAIN}${get(branch, '0.logo.url', '')}`}
                            />
                        </Col>
                        <Col style={{ textAlign: 'center' }} xs={{ offset: 0, span: 24 }} md={{ offset: 0, span: 4 }}>
                            {!isReadOnly && setLang && <Button style={{ backgroundColor: lang === 'zh' ? mainColor : 'white', borderColor: lang === 'zh' ? 'white' : mainColor, color: lang === 'zh' ? 'white' : mainColor, marginRight: '15px' }} size="large" onClick={() => setLang('zh')}>
                                中文
                            </Button>}{!isReadOnly && setLang && <Button style={{ backgroundColor: lang === 'en' ? mainColor : 'white', borderColor: lang === 'en' ? 'white' : mainColor, color: lang === 'en' ? 'white' : mainColor }} size="large" onClick={() => setLang('en')}>
                                English
                            </Button>}
                        </Col>
                        <Col className="comment-title" xs={{ offset: 0, span: 24 }} md={{ span: 6 }}>
                            <span><Title level={3}><FormattedMessage
                                id="Ubypbj"
                                defaultMessage="Comment Card"
                                description="意見書 Comment Card"
                            /></Title>
                                <hr /><b><FormattedMessage
                                    id="BDzEF8"
                                    defaultMessage="Branch"
                                    description="分店 Branch"
                                />: {get(branch, '0.name', null)}</b><hr />
                                <b><FormattedMessage
                                    id="c0qTFD"
                                    defaultMessage="Table No"
                                    description="枱號 Table No"
                                />: {get(queryStrings, 'tableNo', get(currentFormValues, 'shopSeatTableNumber', 'NA'))}</b><hr />
                            </span>
                        </Col>
                    </Row>
                    <Row className="main-desc" style={{ background: mainColor }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={{ offset: 0, span: 24 }} md={{ offset: 6, span: 12 }}>
                            <Title level={3} style={{ color: 'white', textAlign: 'center' }}><FormattedMessage
                                id="7Xjo1R"
                                defaultMessage="Thank you for visiting, please spare a few minutes to fill out this comment card for our continuous improvement. Thank you!"
                                description="多謝閣下蒞臨本店，為提供更完善的服務，希望閣下能抽空填寫此意見書，並提供寶貴意見，多謝合作。  Thank you for visiting, please spare a few minutes to fill out this comment card for our continuous improvement. Thank you!"
                            /></Title>
                            <Title level={3} style={{ color: 'white', textAlign: 'center' }}><FormattedMessage
                                id="6jFGx2"
                                defaultMessage="Thank you for visiting, please spare a few minutes to fill out this comment card for our continuous improvement. Thank you!"
                                description="bbb多謝閣下蒞臨本店，為提供更完善的服務，希望閣下能抽空填寫此意見書，並提供寶貴意見，多謝合作。  Thank you for visiting, please spare a few minutes to fill out this comment card for our continuous improvement. Thank you!"
                            /></Title>
                            <Title level={5} style={{ marginTop: '40px', color: 'white', textAlign: 'center' }}><FormattedMessage
                                id="pYTQf3"
                                defaultMessage="1 being the lowest and 5 being the highest"
                                description="5分為最滿意 1分為最不滿意"
                            /></Title>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={{ offset: 0, span: 24 }} md={{ offset: 6, span: 13 }}>
                            <Form
                                name="choco-comment"
                                colon={false}
                                form={form}
                                ref={setFormRef}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >

                                <Space direction="vertical" size="middle">
                                    <Form.Item
                                        label={<Title level={3}>*<FormattedMessage
                                            id="AhPCJG"
                                            defaultMessage="Food"
                                            description="食物 Food"
                                        /></Title>} style={{ marginTop: '40px', marginBottom: '5px' }}
                                    >
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateFoodTaste" label={<Title level={5}><FormattedMessage
                                        id="fo7DU9"
                                        defaultMessage="Taste"
                                        description="味道 Taste"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateFoodAppearance" label={<Title level={5}><FormattedMessage
                                        id="dHAXfn"
                                        defaultMessage="Appearance"
                                        description="外觀 Appearance"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateFoodTemp" label={<Title level={5}><FormattedMessage
                                        id="CMcpvV"
                                        defaultMessage="Temperature"
                                        description="溫度 Temperature"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateFoodVolume" label={<Title level={5}><FormattedMessage
                                        id="5oVT4K"
                                        defaultMessage="Volume"
                                        description="份量 Volume"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateFoodEfficiency" label={<Title level={5}><FormattedMessage
                                        id="cWJugy"
                                        defaultMessage="Efficiency"
                                        description="提供速度 Efficiency"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        label={<Title level={3}>*<FormattedMessage
                                            id="mhCNE+"
                                            defaultMessage="Service"
                                            description="服務 Service"
                                        /></Title>} style={{ marginTop: '40px', marginBottom: '5px' }}
                                    >
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateServicePolite" label={<Title level={5}><FormattedMessage
                                        id="ElyZK5"
                                        defaultMessage="Politeness"
                                        description="禮貌 Politeness"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateServiceEfficiency" label={<Title level={5}><FormattedMessage
                                        id="SgHYw/"
                                        defaultMessage="Efficiency"
                                        description="效率 Efficiency"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateServiceAttentive" label={<Title level={5}><FormattedMessage
                                        id="7rkQJs"
                                        defaultMessage="Attentiveness"
                                        description="細心 Attentiveness"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        label={<Title level={3}>*<FormattedMessage
                                            id="fXqbXN"
                                            defaultMessage="Shop"
                                            description="店舖環境 Shop"
                                        /></Title>} style={{ marginTop: '40px', marginBottom: '5px' }}
                                    >
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateShopTemp" label={<Title level={5}><FormattedMessage
                                        id="MT+d5S"
                                        defaultMessage="Room Temperature"
                                        description="室溫 Room Temperature"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateShopClean" label={<Title level={5}><FormattedMessage
                                        id="+jPZ1O"
                                        defaultMessage="Cleanliness"
                                        description="清潔 Cleanliness"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="mobile-btn-space" style={{ lineHeight: '40px' }} name="rateShopAmbience" label={<Title level={5}><FormattedMessage
                                        id="Fd/4kY"
                                        defaultMessage="Ambience"
                                        description="氣氛 Ambience"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Row>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={1}>
                                                        1
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={2}>
                                                        2
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={3}>
                                                        3
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={4}>
                                                        4
                                                    </Radio>
                                                </Col>
                                                <Col xs={{ span: 4 }} md={{ span: 4 }}>
                                                    <Radio value={5}>
                                                        5
                                                    </Radio>
                                                </Col>
                                            </Row>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} name="willRevisit" label={<Title level={5}><FormattedMessage
                                        defaultMessage="Will you visit us again"
                                        id="P8kfTf"
                                        description="閣下會否再次光臨本店 Will you visit us again ?"
                                    /></Title>} className="multi-line-radio" style={{ marginTop: '50px' }}>
                                        <Radio.Group size={"large"} onChange={(e) => setWillRevisit(e.target.value)} value={willRevisit}>
                                            <Space direction="vertical">
                                                <Radio value={true}><FormattedMessage
                                                    id="Ebt7cL"
                                                    defaultMessage="Yes"
                                                    description="會 Yes"
                                                /></Radio>
                                                <Radio value={false}>
                                                    <FormattedMessage
                                                        id="0I4iDA"
                                                        defaultMessage="No, for the reason of"
                                                        description="不會，原因 No, for the reason of"
                                                    />:
                                                    {willRevisit === false ? <Form.Item rules={[{
                                                        max: 100, message: intl.formatMessage({
                                                            id: 'X4Vzcm',
                                                            defaultMessage: 'Please enter less than 500 characters',
                                                            description: '請輸入不多於 500 字 Please enter less than 500 characters',
                                                        })
                                                    }, {
                                                        required: true, message: intl.formatMessage({
                                                            defaultMessage: 'Please fill in this field',
                                                            description: '請填寫此欄 Please fill in this field',
                                                            id: 'j+VK33'
                                                        })
                                                    }]} name="willNotRevisitReasonDetail"><Input.TextArea style={{ minWidth: 250, marginTop: 10 }} /></Form.Item> : null}
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="multi-line-radio" name="ackChannel" label={<Title level={5}><FormattedMessage
                                        defaultMessage="How did you know us"
                                        id="SC4Krw"
                                        description="從何得知本店 How did you know us"
                                    />?</Title>}>
                                        <Radio.Group size={"large"} onChange={(e) => setAckChannel(e.target.value)} value={ackChannel}>
                                            <Space direction="vertical">
                                                <Radio value={"BY_CHANCE"}><FormattedMessage
                                                    defaultMessage="By chance"
                                                    id="I04/tW"
                                                    description="偶然經過 By chance"
                                                /></Radio>
                                                <Radio value={"MAGAZINES"}><FormattedMessage
                                                    defaultMessage="Magazines"
                                                    id="s70J0L"
                                                    description="雜誌介紹 Magazines"
                                                /></Radio>
                                                <Radio value={"OPENRICE"}><FormattedMessage
                                                    defaultMessage="OPENRICE"
                                                    id="hQgEn/"
                                                    description="OPENRICE 網站"
                                                /></Radio>
                                                <Radio value={"OTHERS"}>
                                                    <FormattedMessage
                                                        defaultMessage="Others"
                                                        description="其他 Others"
                                                        id="rxEci0"
                                                    />:
                                                    {ackChannel === "OTHERS" ? <Form.Item rules={[{
                                                        max: 100, message: intl.formatMessage({
                                                            defaultMessage: 'Please enter less than 100 characters',
                                                            description: '請輸入不多於 100 字 Please enter less than 100 characters',
                                                            id: 'u6xzQC'
                                                        })
                                                    }, {
                                                        required: true, message: intl.formatMessage({
                                                            id: 'j+VK33',
                                                            defaultMessage: 'Please fill in this field',
                                                            description: '請填寫此欄 Please fill in this field',
                                                        })
                                                    }]} name="ackChannelOthersDetail"><Input.TextArea style={{ minWidth: 250, marginTop: 10 }} /></Form.Item> : null}
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        max: 100, message: intl.formatMessage({
                                            defaultMessage: 'Please enter less than 100 characters',
                                            description: '請輸入不多於 100 字 Please enter less than 100 characters',
                                            id: 'u6xzQC'
                                        })
                                    }, {
                                        required: true, message: intl.formatMessage({
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                            id: 'j+VK33'
                                        })
                                    }]} className="wish-dish-form-item" name="wishDishDetail" label={<Title level={5}><FormattedMessage
                                        defaultMessage="What dish would you like us"
                                        description="你希望我們增加 What dish would you like us"
                                        id="b+LPEz"
                                    /><br /><FormattedMessage
                                            defaultMessage="to add on our menu"
                                            description="哪一種食品/菜式 to add on our menu"
                                            id="pWEM6Y"
                                        />?</Title>}>
                                        <Input.TextArea />
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        max: 500, message: intl.formatMessage({
                                            defaultMessage: 'Please enter less than 500 characters',
                                            description: '請輸入不多於 500 字 Please enter less than 500 characters',
                                            id: 'X4Vzcm'
                                        })
                                    }, {
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} name="additionalCommentDetail" label={<Title level={5}><FormattedMessage
                                        defaultMessage="Additional comments"
                                        description="結予店舖的意見 Additional comments"
                                        id="FxikN2"
                                    /></Title>}>
                                        <Input.TextArea />
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        max: 100, message: intl.formatMessage({
                                            defaultMessage: 'Please enter less than 100 characters',
                                            description: '請輸入不多於 100 字 Please enter less than 100 characters',
                                            id: 'u6xzQC'
                                        })
                                    }, {
                                        required: true, message: intl.formatMessage({
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                            id: "j+VK33"
                                        })
                                    }]} name="customerName" label={<Title level={5}><FormattedMessage
                                        defaultMessage="Name"
                                        description="姓名 Name"
                                        id="+H60vg"
                                    /></Title>}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="multi-line-radio" name="customerGender" label={<Title level={5}><FormattedMessage
                                        defaultMessage="Gender"
                                        description="性別 Gender"
                                        id="dPRgO+"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Space direction="vertical">
                                                <Radio value={"MALE"}><FormattedMessage
                                                    defaultMessage="Male"
                                                    description="男 Male"
                                                    id="hXQvGQ"
                                                /></Radio>
                                                <Radio value={"FEMALE"}><FormattedMessage
                                                    defaultMessage="Female"
                                                    description="女 Female"
                                                    id="FkXazv"
                                                /></Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="multi-line-radio" name="customerAge" label={<Title level={5}><FormattedMessage
                                        defaultMessage="Age"
                                        description="年齡 Age"
                                        id="O9S6Oy"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Space direction="vertical">
                                                <Radio value={1}><FormattedMessage
                                                    defaultMessage="20 Or below"
                                                    description="20 或以下 20 Or below"
                                                    id="bmCy5K"
                                                /></Radio>
                                                <Radio value={2}>20 - 25</Radio>
                                                <Radio value={3}>26 - 30</Radio>
                                                <Radio value={4}>31 - 35</Radio>
                                                <Radio value={5}>36 - 40</Radio>
                                                <Radio value={6}><FormattedMessage
                                                    defaultMessage="41 Or Above"
                                                    description="41 或以上 41 Or Above"
                                                    id="4xvWDz"
                                                /></Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        pattern: /^[0-9]*$/, message: intl.formatMessage({
                                            defaultMessage: 'Please enter a valid phone number',
                                            description: '請輸入正確的電話號碼 Please enter a valid phone number',
                                            id: '4clcoi'
                                        })
                                    }, {
                                        len: 8, message: intl.formatMessage({
                                            defaultMessage: 'Please enter 8 digit number',
                                            description: '請輸入 8 位數字電話號碼 Please enter 8 digit number',
                                            id: 'k1ybWO'
                                        })
                                    }]} name="customerPhoneNumber" label={<Title level={5}><FormattedMessage
                                        defaultMessage="Tel"
                                        description="電話 Tel"
                                        id="o0QPiM"
                                    /></Title>}>
                                        <Input prefix="+852" />
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        type: 'email', message: intl.formatMessage({
                                            defaultMessage: 'Please enter a valid email address',
                                            description: '請輸入正確的電郵地址 Please enter a valid email address',
                                            id: '3fveil'
                                        })
                                    }]} name="customerEmail" label={<Title level={5}><FormattedMessage
                                        defaultMessage="Email"
                                        description="電郵 Email"
                                        id="7PSD9H"
                                    /></Title>}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item rules={[{
                                        required: true, message: intl.formatMessage({
                                            id: "j+VK33",
                                            defaultMessage: 'Please fill in this field',
                                            description: '請填寫此欄 Please fill in this field',
                                        })
                                    }]} className="multi-line-radio" name="customerNumberOfVisit" label={<Title level={5}><FormattedMessage
                                        defaultMessage="Number of Visit"
                                        description="惠顧次數 Number of Visit"
                                        id="cBUYD0"
                                    /></Title>}>
                                        <Radio.Group size={"large"}>
                                            <Space direction="vertical">
                                                <Radio value={"FIRST_TIME"}><FormattedMessage
                                                    defaultMessage="First time"
                                                    description="首次 First time"
                                                    id="nZSQqK"
                                                /></Radio>
                                                <Radio value={"ONCE_PER_WEEK"}><FormattedMessage
                                                    defaultMessage="Once a week"
                                                    description="一星期一次 Once a week"
                                                    id="Rihm8Q"
                                                /></Radio>
                                                <Radio value={"MORE_THAN_ONCE_PER_WEEK"}><FormattedMessage
                                                    defaultMessage="More than once a week"
                                                    description="一星期多於一次 More than once a week"
                                                    id="Hr8ZAR"
                                                /></Radio>
                                                <Radio value={"ONCE_PER_TWO_WEEKS"}><FormattedMessage
                                                    defaultMessage="Twice a week"
                                                    description="兩星期一次 Twice a week"
                                                    id="sjRdc4"
                                                /></Radio>
                                                <Radio value={"ONCE_PER_MONTH"}><FormattedMessage
                                                    defaultMessage="Once a month"
                                                    description="一個月一次 Once a month"
                                                    id="H8s7Lv"
                                                /></Radio>
                                                <Radio value={"ONCE_PER_TWO_MONTHS"}><FormattedMessage
                                                    defaultMessage="Twice a month"
                                                    description="兩個月一次 Twice a month"
                                                    id="/jmuvt"
                                                /></Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item className="will-subscribe-contact-list" name="willSubscribeContactList" label={<Title level={5}></Title>}>
                                        <Checkbox.Group>
                                            <Checkbox value={"UNSUBSCRIBE"}><b><FormattedMessage
                                                defaultMessage="Unsubscribe from contact list"
                                                description="本人不希望收到貴公司之任何資訊 Unsubscribe from contact list"
                                                id="yrqw2E"
                                            /></b></Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>

                                    <Divider />

                                    {!isReadOnly &&
                                        <Form.Item wrapperCol={{ xs: { offset: 0, span: 24 }, md: { offset: 7, span: 10 } }}>
                                            <Button style={{ background: mainColor, borderColor: mainColor }} block size="large" type="primary" htmlType="submit">
                                                <FormattedMessage
                                                    defaultMessage="Submit"
                                                    description="提交 Submit"
                                                    id="syBqY+"
                                                />
                                            </Button>
                                        </Form.Item>}
                                </Space>
                            </Form>
                        </Col>
                    </Row>
                </Spin>
            </Content>
        </Layout >
    );

    return prevSuccessResult;
};

export default FormMidori